import React from "react";
import { Button } from "reactstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const [percentage, setPercentage] = useState("1");
  // const [click, setClick] = useState();
  let earning1 = 1;
  let earning2 = 1.5;
  let earning3 = 1.6;

  function earningOne() {
    setPercentage(earning1);
  }

  function earningTwo() {
    setPercentage(earning2);
  }

  function earningThree() {
    setPercentage(earning3);
  }

  // function handleClick() {
  //   setClick();
  // }

  return (
    <div className="pools-container">
      <div className="pool1">
        <div className="details">
          <div className="asset-imageContainer">
            <img className="usdt" src={props.image} alt="usdt" />
          </div>
          <div className="asset-descriptionContainer">
            <p>{props.name}</p>
            <p className="percentage">
              {props.percentage} {percentage}%
            </p>
          </div>
        </div>
        <div className="days">
          <p>{props.days}</p>
        </div>
        <div className="days-btnContainer">
          <div>
            <Button
              onClick={earningOne}
              className="daysbnt"
              size="lg"
              color="light"
            >
              {" "}
              {props.day1}{" "}
            </Button>
          </div>
          <div>
            <Button
              onClick={earningTwo}
              className="daysbnt"
              size="lg"
              color="light"
            >
              {" "}
              {props.day2}{" "}
            </Button>
          </div>
          <div>
            <Button
              onClick={earningThree}
              className="daysbnt"
              size="lg"
              color="light"
            >
              {" "}
              {props.day3}
            </Button>
          </div>
        </div>
        <div className="check-container">
          <Link to="/dashboard">
            <div className="check-btn">
              <Button className="check" size="lg" color="success">
                {" "}
                {props.stake}{" "}
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
